import React from "react"
import { FormattedMessage } from "react-intl"
import { CreateUserForm } from "../forms/create-user.form"

import Modal from "../modal"

export const CreateUserModal = ({ isOpen, handleClose, handleSuccess }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <h2>
        <FormattedMessage id="createUserModal.title" />
      </h2>
      <CreateUserForm
        handleValidationSuccess={({ firstName, lastName, username }) =>
          handleSuccess(firstName, lastName, username)
        }
      />
    </Modal>
  )
}
