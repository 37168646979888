import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import * as localStorageService from "../services/localstorage.service"
import * as stellarService from "../services/stellar.service"
import DEVPIcon from "./devp-icon"

const WalletBox = () => {
  const [devpBalance, setDevpBalance] = useState(0)

  useEffect(() => {
    const pubKey = localStorageService.getPublicKey()

    stellarService
      .getStellarAccount(pubKey)
      .catch(err => err)
      .then(account => stellarService.getBalance(account, "DEVP"))
      .catch(err => err)
      .then(balance => setDevpBalance(Number(balance).toFixed(4)))
  }, [])
  if (devpBalance > 0) {
    return (
      <Link className="button button-primary flex flex-row" to="/wallet">
        {devpBalance} <DEVPIcon size={24} />
      </Link>
    )
  } else {
    return <span></span>
  }
}

export default WalletBox
