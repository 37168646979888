import React from "react"
import { FormattedMessage } from "react-intl"
import Modal from "../modal"

const AlertAccountCreationModal = ({ isOpen, handleClose, handleSuccess }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <h2>
        <FormattedMessage id="alertAccountCreationModal.title" />
      </h2>
      <p>
        <FormattedMessage id="alertAccountCreationModal.text1" />
      </p>
      <p>
        <FormattedMessage id="alertAccountCreationModal.text2" />
      </p>
      <p>
        <FormattedMessage id="alertAccountCreationModal.text3" />
      </p>
      <p>
        <FormattedMessage id="alertAccountCreationModal.text4" />
      </p>
      <p>
        <FormattedMessage id="alertAccountCreationModal.text5" />
      </p>
      <p>
        <FormattedMessage id="alertAccountCreationModal.text6" />
      </p>
      <button
        className="button button-primary mt-4"
        onClick={() => handleSuccess()}
      >
        <FormattedMessage id="alertAccountCreationModal.button" />
      </button>
    </Modal>
  )
}

export default AlertAccountCreationModal
