import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { graphql, Link, useStaticQuery } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"


import "intl"

import "prismjs/themes/prism-okaidia.css"
import ThemeContext from "../context/theme-context"
import Header from "../components/features/header/Header"
import { Footer } from '../components/features/footer/Footer';

const Layout = ({ children, location, i18nMessages, langPaths }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            languages {
              defaultLangKey
              langs
            }
          }
        }
      }
    `
  )
  const { theme } = useContext(ThemeContext)
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/")
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map(
    item => {
      if (langPaths && langPaths[item.langKey]) {
        return { ...item, link: langPaths[item.langKey] }
      }
      return { ...item, link: item.link.replace(`/${defaultLangKey}/`, "/") }
    }
  )

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Helmet htmlAttributes={{ class: theme }} />
      <Header
        homeLink={homeLink}
        langsMenu={langsMenu}
        siteTitle={data.site.siteMetadata.title}
      />

      <div className="p-8 max-w-7xl mx-auto my-0 md:mt-40">
        {children}
      </div>
      <Footer/>
    </IntlProvider>
  )
}

export default Layout
