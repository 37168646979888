import CryptoJS from "crypto-js"

export const encryptSecret = (secret, key) => {
  return CryptoJS.AES.encrypt(secret, key).toString()
}

export const decryptSecret = (secret, key) => {
  return CryptoJS.AES.decrypt(secret, key).toString(CryptoJS.enc.Utf8)
}

export const createRouteHash = route => {
  return CryptoJS.AES.encrypt(route, "generic").toString()
}

export const decodeRouteHash = routeHash => {
  return CryptoJS.AES.decrypt(routeHash, "generic").toString(CryptoJS.enc.Utf8)
}
