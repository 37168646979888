import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import validationService from "../../services/validation.service"

const ImportAccountForm = ({ onSubmit }) => {
  const [privateKey, setPrivateKey] = useState("")
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [formErrors, setFormErrors] = useState({})

  const validatePrivateKey = privateKey => {
    const [result, errors] = validationService.validatePrivateKey(privateKey)
    setFormErrors(formErrors => ({ ...formErrors, ...errors }))
    return result
  }
  const validatePassword = (password, repeatPassword) => {
    const [result, errors] = validationService.validatePassword(
      password,
      repeatPassword
    )
    setFormErrors(formErrors => ({ ...formErrors, ...errors }))
    return result
  }

  const handleSubmit = event => {
    event.preventDefault()
    setFormErrors({})
    if (
      validatePrivateKey(privateKey) &&
      validatePassword(password, repeatPassword)
    ) {
      onSubmit(privateKey, password)
    }
  }

  return (
    <form className="form-container mt-6">
      <label htmlFor="privateKey">
        <FormattedMessage id="importAccountForm.privateKeyLabel" />
      </label>
      <input
        type="text"
        name="privateKey"
        id="privateKey"
        onChange={({ target: { value } }) => setPrivateKey(value)}
      />
      <label htmlFor="password">
        <FormattedMessage id="importAccountForm.passwordLabel" />
      </label>
      <input
        type="password"
        name="password"
        id="password"
        onChange={({ target: { value } }) => setPassword(value)}
      />
      <label htmlFor="repeatPassword">
        <FormattedMessage id="importAccountForm.repeatPasswordLabel" />
      </label>
      <input
        type="password"
        name="repeatPassword"
        id="repeatPassword"
        onChange={({ target: { value } }) => setRepeatPassword(value)}
      />
      <div className="form-errors">
        {formErrors.emptyPrivateKey && (
          <p>
            <FormattedMessage id="importAccountForm.emptyPrivateKeyError" />
          </p>
        )}
        {formErrors.emptyPassword && (
          <p>
            <FormattedMessage id="importAccountForm.emptyPasswordError" />
          </p>
        )}
        {!formErrors.emptyPassword && formErrors.shortPassword && (
          <p>
            <FormattedMessage id="importAccountForm.shortPasswordError" />
          </p>
        )}
        {!formErrors.emptyPassword &&
          !formErrors.shortPassword &&
          formErrors.differentPasswords && (
            <p>
              <FormattedMessage id="importAccountForm.differentPasswordsError" />
            </p>
          )}
      </div>
      <button className="button button-primary" onClick={e => handleSubmit(e)}>
        <FormattedMessage id="importAccountForm.submitButton" />
      </button>
    </form>
  )
}

export default ImportAccountForm
