import axios from "axios"
import {
  getJwt as getToken,
  removeJwt,
  setJwt,
} from "./localstorage.service.js"

const baseUrl = process.env.GATSBY_API_BASE_URL

const requestInterceptor = config => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${getToken()}`,
  },
})

export const apiRequest = axios.create({
  baseURL: `${baseUrl}/api`,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
})

export class TokenError extends Error {}

const errorInterceptor = async error => {
  try {
    const refreshResponse = await axios.post(
      `${baseUrl}/api/auth/refresh_token`,
      {},
      { withCredentials: true }
    )
    setJwt(refreshResponse.data.token)
    return error
  } catch (e) {
    removeJwt()
    return e
  }
}

apiRequest.interceptors.request.use(requestInterceptor)
apiRequest.interceptors.response.use(response => response, errorInterceptor)
