import React, { useState } from "react"

import Modal from "../modal"

import * as cryptoService from "../../services/crypto.service"
import * as localStorageService from "../../services/localstorage.service"
import { FormattedMessage } from "react-intl"

const ShowPrivateKeyModal = ({ isOpen, handleClose, handleSuccess }) => {
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    setErrors(false)
    const encryptedPrivateKey = localStorageService.getEncryptedSecret()
    try {
      const decryptedPrivateKey = cryptoService.decryptSecret(
        encryptedPrivateKey,
        password
      )
      if (decryptedPrivateKey) {
        handleSuccess(decryptedPrivateKey)
      } else {
        setErrors(true)
      }
    } catch (e) {
      console.log(e)
      setErrors(true)
    }
  }

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <form onSubmit={e => handleSubmit(e)} className="form-container">
        <label htmlFor="password">
          <FormattedMessage id="showPrivateKeyModal.passwordLabel" />
        </label>
        <input
          id="password"
          name="password"
          type="password"
          onChange={e => {
            setErrors(false)
            setPassword(e.target.value)
          }}
        />
        {errors && (
          <div className="errors">
            <FormattedMessage id="showPrivateKeyModal.wrongPasswordError" />
          </div>
        )}
        <button className="button -primary" onClick={e => handleSubmit(e)}>
          <FormattedMessage id="showPrivateKeyModal.revealPrivateKeyButton" />
        </button>
      </form>
    </Modal>
  )
}

export default ShowPrivateKeyModal
