import React from "react"

const Modal = ({ isOpen, handleClose, children }) => {
  const _handleClose = e => {
    if (e.target.className.includes("modal-overlay")) {
      handleClose()
    }
  }

  const _handleCloseEscape = e => {
    if (["Esc", "Escape"].includes(e.key)) {
      handleClose()
    }
  }
  return isOpen ? (
    <div
      className="modal-overlay inset-0 fixed bg-overlay p-12 z-40 overflow-y-scroll"
      onClick={_handleClose}
      onKeyPress={_handleCloseEscape}
      role="dialog"
    >
      <div className="modal-window min-w-sm max-w-xl m-auto bg-white dark:bg-secondary-dark rounded-lg p-4">
        {children}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default Modal
