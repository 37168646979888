const validationService = {
  validatePassword: (password, repeatPassword) => {
    let errors = {}
    let hasErrors = false
    if (!password) {
      hasErrors = true
      errors = { ...errors, emptyPassword: true }
    }
    if (password.length < 8) {
      hasErrors = true
      errors = { ...errors, shortPassword: true }
    }
    if (password !== repeatPassword) {
      hasErrors = true
      errors = { ...errors, differentPasswords: true }
    }
    return [!hasErrors, errors]
  },
  validatePrivateKey: privateKey => {
    let errors = {}
    let hasErrors = false
    if (!privateKey) {
      hasErrors = true
      errors = { ...errors, emptyPrivateKey: true }
    }
    return [!hasErrors, errors]
  },
}

export default validationService
