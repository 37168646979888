import React from "react"
import ThemeContext from "../context/theme-context"

const ThemeChanger = () => (
  <ThemeContext.Consumer>
    {({ theme, toggleTheme }) => (
      <label>
        <input
          type="checkbox"
          className="hidden"
          onChange={e => toggleTheme(e.target.checked ? "dark" : "light")}
          checked={theme === "dark"}
        />{" "}
        <div className="mode-container w-6 h-6">
          {theme === "dark" && <i className="gg-sun block"></i>}
          {theme !== "dark" && <i className="gg-moon block"></i>}
        </div>
      </label>
    )}
  </ThemeContext.Consumer>
)

export default ThemeChanger
