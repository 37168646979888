import React from "react"
import { Link } from "gatsby"
import { FormattedMessage, useIntl } from "react-intl"

import LanguageBox from "../../language-box"
import PropTypes from "prop-types"
import ThemeChanger from "../../themeChanger"

const SessionDropdownMenu = ({ langs, onClose }) => {
  const intl = useIntl()
  const profileUrl =
    intl && intl.locale === "en" ? "/profile" : `/${intl.locale}/perfil`
  const walletUrl =
    intl && intl.locale === "en" ? "/wallet" : `/${intl.locale}/monedero`
  const accountUrl =
    intl && intl.locale === "en" ? "/account" : `/${intl.locale}/cuenta`

  return (
    <div className="flex flex-col items-center p-4">
      <article>
        <Link to={profileUrl}>
          <FormattedMessage id="dropdownMenu.profile" />
        </Link>
      </article>
      <article>
        <Link to={walletUrl}>
          <FormattedMessage id="dropdownMenu.wallet" />
        </Link>
      </article>
      <article>
        <Link to={accountUrl}>
          <FormattedMessage id="dropdownMenu.account" />
        </Link>
      </article>
      <article>
        <FormattedMessage id="dropdownMenu.logout" />
      </article>
      <LanguageBox langs={langs} />
      <ThemeChanger />
    </div>
  )
}

LanguageBox.propTypes = {
  langs: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SessionDropdownMenu
