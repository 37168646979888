import axios from "axios"
import { getJwt as getToken } from "./localstorage.service.js"

// General data checks

export const checkPassword = (password, passwordConfirm) => {
  if (password !== passwordConfirm)
    return {
      status: false,
      message: "Password and confirmation must be equal",
    }
  if (password.length < 8)
    return {
      status: false,
      message: "Password must be at least 8 characters long.",
    }
  return {
    status: true,
  }
}

const createGenericErrorMessage = errors =>
  errors.map(error => `- ${error.message}`).join("<br>")

export const performMultipleChecks = (...check) => {
  let errors = []
  check.forEach(check => {
    if (!check.status) errors.push(check)
  })
  if (errors.length > 0)
    return {
      status: false,
      message: createGenericErrorMessage(errors),
    }

  return {
    status: true,
  }
}

// Login checks

export const checkLoginData = data => {
  if (!data.privateKey)
    return {
      status: false,
      message: "PrivateKey is required",
    }
  if (!data.password)
    return {
      status: false,
      message: "Password is required",
    }
  return {
    status: true,
  }
}

// Register checks

export const checkRegisterData = data => {
  if (!data.firstName)
    return {
      status: false,
      message: "First name is required",
    }
  if (!data.lastName)
    return {
      status: false,
      message: "Last name is required",
    }
  if (!data.username)
    return {
      status: false,
      message: "Username is required",
    }
  const usernameFormat = checkUsernameFormat(data.username)
  if (!usernameFormat.status)
    return {
      status: false,
      message: createFailMessage(usernameFormat),
    }
  return {
    status: true,
  }
}

export const prepareDataForRegisterRequest = data => ({
  firstName: data.firstName,
  lastName: data.lastName,
  username: data.username,
  publicKey: data.publicKey,
})

const checkUsernameFormat = username => {
  const regex = /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$/
  const hasSpecialCharacters = /[^a-zA-Z0-9]/.test(username)
  const startsWithNonAlphabeticalCharacter = /^[^a-zA-Z]/.test(
    username.charAt(0)
  )
  const hasSpaces = /\s/.test(username)
  const length = username.length
  return {
    status: regex.test(username),
    hasSpecialCharacters,
    startsWithNonAlphabeticalCharacter,
    hasSpaces,
    length,
  }
}

const createFailMessage = data => {
  let message = "Username cannot:<br>"
  if (data.hasSpecialCharacters) message += "- Contain special characters.<br>"
  if (data.startsWithNonAlphabeticalCharacter)
    message += "- Start with a non alphabetical character.<br>"
  if (data.hasSpaces) message += "- Have spaces.<br>"
  if (data.length < 3 || data.length > 18)
    message += "- Be shorter shorter 3 or larger than 18 characters.<br>"
  const cleanLastBr = message.replace(/<br>$/, "")
  return cleanLastBr
}
