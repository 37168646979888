import React from "react"
import { FormattedMessage } from "react-intl"
import CreateAccountForm from "../forms/create-account-form"
import Modal from "../modal"

const CreateAccountModal = ({
  isOpen,
  handleClose,
  handleSuccess,
  keyPair,
}) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <h2>
        <FormattedMessage id="createAccountModal.title" />
      </h2>
      {keyPair && (
        <div>
          <FormattedMessage id="createAccountModal.publicKey" />{" "}
          <div className="p-4 overflow-x-scroll lg:overflow-auto mb-4">
            {keyPair.publicKey()}
          </div>
        </div>
      )}
      {keyPair && (
        <div>
          <FormattedMessage id="createAccountModal.privateKey" />{" "}
          <div className="p-4 overflow-x-scroll lg:overflow-auto mb-4">
            {keyPair.secret()}
          </div>
        </div>
      )}
      <CreateAccountForm
        handleValidationSuccess={pass => handleSuccess(pass)}
      />
    </Modal>
  )
}

export default CreateAccountModal
