import React from "react"
import { FormattedMessage } from "react-intl"

import ImportAccountForm from "../forms/import-account-form"
import Modal from "../modal"

const ImportAccountModal = ({ isOpen, handleClose, handleSuccess }) => {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <h2>
        <FormattedMessage id="importAccountModal.title" />
      </h2>
      <ImportAccountForm
        onSubmit={(privateKey, password) => handleSuccess(privateKey, password)}
      />
    </Modal>
  )
}

export default ImportAccountModal
