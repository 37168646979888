import React, { useCallback } from "react"
import { Link } from "gatsby"
import HeaderNavigation from "./headerNavigation"
import PropTypes from "prop-types"
import { useToggle } from "../../common/hooks/useToggle"
import Hamburguer from "./Hamburguer"
import HeaderDropdown from "./HeaderDropdown"
import { useBreakpoint } from "../../common/hooks/useBreakpoint"
import HeaderDropdownMenu from "./HeaderDropdownMenu"
import cn from "classnames"
import SessionDropdownMenu from "./SessionDropdownMenu"

const Header = ({ homeLink, siteTitle, langsMenu }) => {
  const [showDropdown, toggleDropdown, setShowDropdown] = useToggle(false)
  const [showSessionDropdown, toggleSessionDropdown, setShowSessionDropdown] =
    useToggle(false)

  const closeDropdownMenu = useCallback(
    () => setShowDropdown(false),
    [setShowDropdown]
  )

  const closeSessionDropdownMenu = useCallback(
    () => setShowSessionDropdown(false),
    [setShowSessionDropdown]
  )

  const { md } = useBreakpoint()

  return (
    <header>
      <div className="h-20 md:hidden" />
      <HeaderDropdown isOpen={showDropdown} onClose={closeDropdownMenu}>
        <HeaderDropdownMenu onClose={closeDropdownMenu} langs={langsMenu} />
      </HeaderDropdown>
      <HeaderDropdown
        isOpen={showSessionDropdown}
        onClose={closeSessionDropdownMenu}
      >
        <SessionDropdownMenu
          onClose={closeSessionDropdownMenu}
          langs={langsMenu}
        />
      </HeaderDropdown>
      <div className="fixed top-0 inset-x-0 z-30">
        <div
          className={cn(
            "bg-defaultBackground dark:bg-cardBackground-dark shadow-light flex justify-between items-center p-4 pr-2 py-2 md:pr-8 md:py-8 md:p-8"
          )}
        >
          <div className="uppercase no-underline font-black text-primary dark:text-primary-dark">
            <Link to={homeLink}>{md ? siteTitle : "DevLand"}</Link>
          </div>
          <div className="flex items-center">
            <HeaderNavigation
              langs={langsMenu}
              showSessionDropdown={showSessionDropdown}
              openSessionDropdown={() => {
                closeDropdownMenu()
                toggleSessionDropdown()
              }}
            />
            <div className="md:hidden">
              <Hamburguer
                isActive={showDropdown}
                onClick={() => {
                  closeSessionDropdownMenu()
                  toggleDropdown()
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  homeLink: PropTypes.string,
  siteTitle: PropTypes.string,
  langsMenu: PropTypes.any,
}

export default Header
