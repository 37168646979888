import React from "react"

import ThemeChanger from "../../themeChanger"

import LanguageBox from "../../language-box"
import SessionBox from "../../session-box"
import WalletBox from "../../wallet-box"

const HeaderNavigation = props => {
  return (
    <nav className="flex items-center font-light">
      <div className="mr-5 hidden md:block">
        <LanguageBox langs={props.langs} />
      </div>

      <SessionBox
        showSessionDropdown={props.showSessionDropdown}
        openSessionMenu={props.openSessionDropdown}
      />
      <WalletBox />
      <div className="pl-5 pt-2 hidden md:block">
        <ThemeChanger />
      </div>
    </nav>
  )
}

export default HeaderNavigation
