import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"

import { FormattedMessage, useIntl } from "react-intl"

import SessionContext from "../context/session.context"

import * as stellarService from "../services/stellar.service"
import * as cryptoService from "../services/crypto.service"
import * as localStorageService from "../services/localstorage.service"
import { auth, register } from "../services/auth.service"
import { shortenPublicKey } from "../services/utils.service"

import AlertAccountCreationModal from "./modals/alert-account-creation-modal"
import CreateAccountModal from "./modals/create-account-modal"
import ImportAccountModal from "./modals/import-account-modal"
import { CreateUserModal } from "./modals/create-user-modal"
import { getProfile } from "../services/profile.service"
import { apiRequest, TokenError } from "../services/api.service"
import ShowPrivateKeyModal from "./modals/show-private-key-modal"

// TODO: Nunca se debe acoplar tanto la lógica de la aplicación con la UI, especialmente en una app tan grande como DEVLand. Debería refactorizarse y crear un hook para esto.
// TODO: Crear una store para mostrar/ocultar modales, para poder gestionar su apertura y cerrado desde más de un componente.
const SessionBox = ({ showSessionDropdown, openSessionMenu }) => {
  const {
    isLoggedIn,
    setIsLoggedIn,
    setPublicKey,
    sessionIsActive,
    setSessionIsActive,
    setUsername,
    username,
  } = useContext(SessionContext)

  const [shortPubKey, setShortPubKey] = useState(null)
  const [keyPair, setKeyPair] = useState(null)

  const [importAccountModalIsOpen, setImportAccountModalIsOpen] =
    useState(false)
  const [alertAccountCreationModalIsOpen, setAlertAccountCreationModalIsOpen] =
    useState(false)
  const [createAccountModalIsOpen, setCreateAccountModalIsOpen] =
    useState(false)
  const [createUserModalIsOpen, setCreateUserModalIsOpen] = useState(false)
  const [showPrivateKeyModalIsOpen, setShowPrivateKeyModalIsOpen] =
    useState(false)

  useEffect(() => {
    if (localStorageService.getJwt()) {
      getProfile()
        .then(profile => {
          setUsername(profile.username)
          setSessionIsActive(true)
        })
        .catch(e => {
          getProfile()
            .then(
              profile =>
                setUsername(profile.username) && setSessionIsActive(true)
            )
            .catch(() => setSessionIsActive(false))
        })
    } else if (
      localStorageService.getPublicKey() &&
      localStorageService.getEncryptedSecret()
    ) {
      setShowPrivateKeyModalIsOpen(true)
    }
  }, [sessionIsActive])

  const handleCreateAccount = () => {
    setKeyPair(stellarService.createKeyPair())
  }
  const handleEncryptSecret = password => {
    const encryptedSecret = cryptoService.encryptSecret(
      keyPair.secret(),
      password
    )
    localStorageService.setPublicKey(keyPair.publicKey())
    localStorageService.setEncryptedSecret(encryptedSecret)
    setPublicKey(keyPair.publicKey())
    setIsLoggedIn(true)
    setCreateAccountModalIsOpen(false)
    setCreateUserModalIsOpen(true)
  }
  const handleImportAccount = async (privateKey, password) => {
    const encryptedSecret = cryptoService.encryptSecret(privateKey, password)
    const keyPair = stellarService.getKeyPair(privateKey)
    setKeyPair(keyPair)
    localStorageService.setPublicKey(keyPair.publicKey())
    localStorageService.setEncryptedSecret(encryptedSecret)
    setPublicKey(keyPair.publicKey())
    setIsLoggedIn(true)

    auth(keyPair.secret())
      .then(token => {
        localStorageService.setJwt(token)
        setSessionIsActive(true)
      })
      .catch(error => {
        setCreateUserModalIsOpen(true)
      })
      .finally(() => setImportAccountModalIsOpen(false))
  }

  const handleCreateFromLegacy = async privateKey => {
    const keyPair = stellarService.getKeyPair(privateKey)
    setKeyPair(keyPair)
    setPublicKey(keyPair.publicKey())
    auth(keyPair.secret())
      .then(token => {
        localStorageService.setJwt(token)
        setSessionIsActive(true)
      })
      .catch(error => {
        setCreateUserModalIsOpen(true)
      })
      .finally(() => setShowPrivateKeyModalIsOpen(false))
  }

  const handleCreateUser = async (firstName, lastName, username) => {
    try {
      await register(firstName, lastName, username, keyPair.publicKey())
      const token = await auth(keyPair.secret())
      localStorageService.setJwt(token)
      setSessionIsActive(true)
      setCreateUserModalIsOpen(false)
    } catch (e) {
      setSessionIsActive(false)
      console.log(e)
    }
  }

  const intl = useIntl()
  const accountUrl =
    intl && intl.locale === "en" ? "/account" : `/${intl.locale}/cuenta`

  return (
    <>
      {!isLoggedIn && (
        <>
          <button
            className="button button-primary md:button-lg"
            onClick={() => setAlertAccountCreationModalIsOpen(true)}
          >
            <FormattedMessage id="sessionBox.createAccountButton" />
          </button>
          <button
            className="button md:button-lg hidden sm:block"
            onClick={() => setImportAccountModalIsOpen(true)}
          >
            <FormattedMessage id="sessionBox.importAccountButton" />
          </button>
        </>
      )}
      {isLoggedIn && sessionIsActive && (
        <button
          className={`button ${
            showSessionDropdown ? "button-primary" : ""
          } md:button-lg`}
          onClick={() => openSessionMenu()}
        >
          {username}
        </button>
      )}
      <AlertAccountCreationModal
        isOpen={alertAccountCreationModalIsOpen}
        handleClose={() => setAlertAccountCreationModalIsOpen(false)}
        handleSuccess={() => {
          setAlertAccountCreationModalIsOpen(false)
          handleCreateAccount()
          setCreateAccountModalIsOpen(true)
        }}
      />
      <CreateAccountModal
        isOpen={createAccountModalIsOpen}
        keyPair={keyPair}
        handleClose={() => setCreateAccountModalIsOpen(false)}
        handleSuccess={password => handleEncryptSecret(password)}
      />
      <CreateUserModal
        isOpen={createUserModalIsOpen}
        handleClose={() => setCreateUserModalIsOpen(false)}
        handleSuccess={(firstName, lastName, username) =>
          handleCreateUser(firstName, lastName, username)
        }
      />
      <ImportAccountModal
        isOpen={importAccountModalIsOpen}
        handleClose={() => setImportAccountModalIsOpen(false)}
        handleSuccess={(privateKey, password) =>
          handleImportAccount(privateKey, password)
        }
      />
      <ShowPrivateKeyModal
        isOpen={showPrivateKeyModalIsOpen}
        handleClose={() => setShowPrivateKeyModalIsOpen(false)}
        handleSuccess={privateKey => handleCreateFromLegacy(privateKey)}
      />
    </>
  )
}

export default SessionBox
