
import { useEffect, useState } from 'react';


/**
 * Tailwind css breakpoints
 * @return {{md: boolean, sm: boolean, lg: boolean}}
 */
const getInitialBreakpoints = () => ({
  sm: false,
  md: false,
  lg: false,
})

// TODO: Move state to global state for better performance
/**
 * Returns the current breakpoint. It uses the Tailwind CSS classes.
 * @warning Use sparingly! It is more performant to hide elements with css classes.
 * @return {{md: boolean, sm: boolean, lg: boolean}}
 */
export const useBreakpoint = () => {
  const [breakpoints, setBreakPoints] = useState(getInitialBreakpoints());
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(handleResize, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    const breakpoints = getInitialBreakpoints()

    setBreakPoints({
      sm: windowSize.width > 640,
      md: windowSize.width > 768,
      lg: windowSize.width > 1024
    })

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.width]);
  return breakpoints;
};
