import React, { useState } from "react"
import { FormattedMessage } from "react-intl"

export const CreateUserForm = ({ handleValidationSuccess }) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [username, setUsername] = useState("")
  const [formErrors, setFormErrors] = useState({})

  const handleSubmit = e => {
    e.preventDefault()
    handleValidationSuccess({ firstName, lastName, username })
  }

  return (
    <form className="form-container" onSubmit={e => handleSubmit(e)}>
      <label htmlFor="username">
        <FormattedMessage id="createUserForm.username" />
      </label>
      <input
        type="text"
        name="username"
        id="username"
        onChange={e => setUsername(e.target.value)}
      />
      <label htmlFor="firstName">
        <FormattedMessage id="createUserForm.firstName" />
      </label>
      <input
        type="text"
        name="firstName"
        id="firstName"
        onChange={e => setFirstName(e.target.value)}
      />
      <label htmlFor="lastName">
        <FormattedMessage id="createUserForm.lastName" />
      </label>
      <input
        type="text"
        name="lastName"
        id="lastName"
        onChange={e => setLastName(e.target.value)}
      />
      <button className="button button-primary" onClick={e => handleSubmit(e)}>
        <FormattedMessage id="createUserForm.submitButton" />
      </button>
    </form>
  )
}
