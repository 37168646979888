import React from "react"
import { Link } from "gatsby"
import { FormattedMessage, useIntl } from "react-intl"

import LanguageBox from "../../language-box"
import PropTypes from "prop-types"
import ThemeChanger from "../../themeChanger"

const HeaderDropdownMenu = ({ langs, onClose }) => {
  const intl = useIntl()
  const aboutDevlandUrl =
    intl && intl.locale === "en"
      ? "/about-devland"
      : `/${intl.locale}/acerca-de-devland`
  const aboutDEVPUrl =
    intl && intl.locale === "en"
      ? "/about-devp"
      : `/${intl.locale}/acerca-de-devp`
  const aboutSNIPPETUrl =
    intl && intl.locale === "en"
      ? "/about-snippet"
      : `/${intl.locale}/acerca-de-snippet`
  const roadmapUrl =
    intl && intl.locale === "en" ? "/roadmap" : `/${intl.locale}/roadmap`
  const faqUrl =
    intl && intl.locale === "en"
      ? "/faq"
      : `/${intl.locale}/preguntas-frecuentes`

  return (
    <div className="flex flex-col items-center p-4">
      <article>
        <Link to={aboutDevlandUrl}>
          <FormattedMessage id="dropdownMenu.aboutDevland" />
        </Link>
      </article>
      <article>
        <Link to={aboutDEVPUrl}>
          <FormattedMessage id="dropdownMenu.aboutDevp" />
        </Link>
      </article>
      <article>
        <Link to={aboutSNIPPETUrl}>
          <FormattedMessage id="dropdownMenu.aboutSnippet" />
        </Link>
      </article>
      <article>
        <Link to={roadmapUrl}>
          <FormattedMessage id="dropdownMenu.roadmap" />
        </Link>
      </article>
      <article>
        <Link to={faqUrl}>
          <FormattedMessage id="dropdownMenu.faq" />
        </Link>
      </article>
      <LanguageBox langs={langs} />
      <ThemeChanger />
    </div>
  )
}

LanguageBox.propTypes = {
  langs: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default HeaderDropdownMenu
