import React from "react"

const DEVPIcon = ({ size }) => {
  return (
    <div
      style={{
        marginLeft: 4,
        backgroundImage: `url(/devp.png)`,
        width: size,
        height: size,
        backgroundSize: size,
      }}
    ></div>
  )
}

export default DEVPIcon
