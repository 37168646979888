import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

// TODO: Refactor: Is it possible to use lang as a hook instead of passing it everywhere as props?
const LanguageBox = ({ langs }) => {
  const links = langs.map(lang => (
    <li
      className="mr-2 last-child:mr-0"
      selected={lang.selected}
      key={lang.langKey}
    >
      <Link
        className="text-gray-500 no-underline hover:text-primary dark:hover:text-primary-dark"
        to={lang.link}
      >
        {lang.langKey}
      </Link>
    </li>
  ))

  return (
    <section>
      <ul className="list-none flex p-2">{links}</ul>
    </section>
  )
}

LanguageBox.propTypes = {
  langs: PropTypes.array.isRequired,
}

export default LanguageBox
