import React, { useState } from "react"
import { FormattedMessage } from "react-intl"

import validationService from "../../services/validation.service"

const CreateAccountForm = ({ handleValidationSuccess }) => {
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [formErrors, setFormErrors] = useState({})

  const validate = (password, repeatPassword) => {
    const [result, errors] = validationService.validatePassword(
      password,
      repeatPassword
    )
    setFormErrors(errors)
    return result
  }

  const handleSubmit = e => {
    e.preventDefault()
    setFormErrors({})
    if (validate(password, repeatPassword)) {
      handleValidationSuccess(password)
    }
  }

  return (
    <form className="form-container" onSubmit={e => handleSubmit(e)}>
      <label htmlFor="password">
        <FormattedMessage id="createAccountForm.passwordLabel" />
      </label>
      <input
        type="password"
        name="password"
        id="password"
        onChange={e => setPassword(e.target.value)}
      />
      <label htmlFor="repeatPassword">
        <FormattedMessage id="createAccountForm.repeatPasswordLabel" />
      </label>
      <input
        type="password"
        name="repeatPassword"
        id="repeatPassword"
        onChange={e => setRepeatPassword(e.target.value)}
      ></input>
      <div className="form-errors">
        {formErrors.emptyPassword && (
          <p>
            <FormattedMessage id="createAccountForm.emptyPasswordError" />
          </p>
        )}
        {!formErrors.emptyPassword && formErrors.shortPassword && (
          <p>
            <FormattedMessage id="createAccountForm.shortPasswordError" />
          </p>
        )}
        {!formErrors.emptyPassword &&
          !formErrors.shortPassword &&
          formErrors.differentPasswords && (
            <p>
              <FormattedMessage id="createAccountForm.differentPasswordsError" />
            </p>
          )}
      </div>

      <button className="button button-primary" onClick={e => handleSubmit(e)}>
        <FormattedMessage id="createAccountForm.submitButton" />
      </button>
    </form>
  )
}

export default CreateAccountForm
