import React from "react"
import Hamb from "hamburger-react"
import PropTypes from "prop-types"

const Hamburguer = ({ isActive, onClick }) => {
  return (
    <Hamb
      toggled={isActive}
      onToggle={onClick}
      color="currentColor"
      rounded={true}
      hideOutline={true}
      size={28}
    />
  )
}

Hamburguer.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Hamburguer
