import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import cn from "classnames"

const HeaderDropdown = ({ isOpen, onClose, children }) => {
  const [show, setShow] = useState(false)
  const [unfold, setUnfold] = useState(false)
  useEffect(() => {
    if (isOpen) {
      setShow(true)
      const timeout = setTimeout(() => {
        setUnfold(true)
      }, 10)
      return () => clearTimeout(timeout)
    } else {
      setUnfold(false)
      const timeout = setTimeout(() => {
        setShow(false)
      }, 1200)
      return () => clearTimeout(timeout)
    }
  }, [isOpen])

  if (!show) {
    return null
  }
  return (
    <div className="fixed md:hidden inset-0 z-30">
      <button
        className={cn(
          "absolute block w-full h-full bg-black duration-1000 transition-opacity cursor-default",
          unfold ? "opacity-50" : "opacity-0"
        )}
        onClick={onClose}
        aria-label="Close Menu"
      />
      <nav
        className={cn(
          "relative overflow-y-auto light:shadow-gray dark:shadow-dark w-full duration-1000 bg-defaultBackground dark:bg-defaultBackground-dark transition-all",
          unfold ? "h-80" : "h-0"
        )}
      >
        <div className="relative h-16" />
        {children}
      </nav>
    </div>
  )
}

HeaderDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default HeaderDropdown
