import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "gatsby"
import { FaTwitter, FaYoutube, FaDiscord, FaTelegram } from "react-icons/fa"
import { SiDtube } from "react-icons/si"
import devp from "../../../../static/devp.png"
import snippet from "../../../../static/snippet.png"

export const Footer = () => {
  const intl = useIntl()
  const aboutDevlandUrl =
    intl && intl.locale === "en"
      ? "/about-devland"
      : `/${intl.locale}/acerca-de-devland`
  const aboutDEVPUrl =
    intl && intl.locale === "en"
      ? "/about-devp"
      : `/${intl.locale}/acerca-de-devp`
  const aboutSNIPPETUrl =
    intl && intl.locale === "en"
      ? "/about-snippet"
      : `/${intl.locale}/acerca-de-snippet`
  const roadmapUrl =
    intl && intl.locale === "en" ? "/roadmap" : `/${intl.locale}/roadmap`
  const faqUrl =
    intl && intl.locale === "en"
      ? "/faq"
      : `/${intl.locale}/preguntas-frecuentes`

  return (
    <footer className="mt-32 text-primary text-center flex flex-col bg-cardBackground dark:bg-cardBackground-dark px-20 pt-20 pb-10">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-10">
        <div className="md:col-span-2 flex flex-col">
          <div className="flex flex-col md:flex-row relative py-1 mb-4 items-center">
            <img src={devp} className="h-6 mb-4 md:mb-0 mr-auto md:mr-4"></img>
            <div className="flex flex-col items-start text-left mr-auto">
              <div className="text-sm font-bold">DEVP</div>
              <div className="text-xs break-all">
                GBJZ2J6JJLWK2PND4ZAWTYKMRAOLDZPOUMZFY6ISIQPOY6HFOL5TIPCR
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row relative py-1 mb-4 items-center">
            <img
              src={snippet}
              className="h-6 mb-4 md:mb-0 mr-auto md:mr-4"
            ></img>
            <div className="flex flex-col items-start text-left mr-auto">
              <div className="text-sm font-bold">SNIPPET</div>
              <div className="text-xs break-all">
                GCN2I52BQDAHGM2AYZGRJ3N4YQSDTUCHXLITKYFAFCG5DKSEVIJFK4FF
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row relative py-1 mb-4 items-center">
            <div className="flex flex-col items-start text-left mr-auto">
              <div className="text-xs pr-8">
                <FormattedMessage id="footer.tokenDisclaimer" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col items-start text-left">
            <p className="text-md font-bold mb-4">
              <FormattedMessage id="footer.about" />
            </p>
            <ul className="text-sm">
              <li className="mb-2">
                <Link to={aboutDevlandUrl}>
                  <FormattedMessage id="footer.about.DevLand" />
                </Link>
              </li>
              <li className="mb-2">
                <Link to={aboutDEVPUrl}>
                  <FormattedMessage id="footer.about.Devp" />
                </Link>
              </li>
              <li className="mb-2">
                <Link to={aboutSNIPPETUrl}>
                  <FormattedMessage id="footer.about.Snippet" />
                </Link>
              </li>
              {/* <li className="mb-2">
              <Link to={faqUrl}>
                <FormattedMessage id="footer.about.faq" />
              </Link>
            </li> */}
            </ul>
          </div>
        </div>
        <div>
          <div className="flex flex-col items-start text-left">
            <p className="text-md font-bold mb-4">
              <FormattedMessage id="footer.roadmap" />
            </p>
            <ul className="text-sm">
              <li className="mb-2">
                <Link to={roadmapUrl}>DevLand Network</Link>
              </li>
              <li className="mb-2">
                <Link to={roadmapUrl}>DevLand Academy</Link>
              </li>
              <li className="mb-2">
                <Link to={roadmapUrl}>DevLand WorkEngine</Link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className="flex flex-col items-start justify-center text-left">
            <p className="text-md font-bold mb-4">
              <FormattedMessage id="footer.socialMedia" />
            </p>
            <div className="grid grid-cols-5 gap-4 text-3xl">
              {/* <a rel="noreferer" target="_blank" href="#">
              <FaTwitter />
            </a> */}
              {/* <a
              rel="noreferer"
              target="_blank"
              href="https://t.me/+A-50Xc6-0d5iNTM0"
            >
              <FaTelegram />
            </a> */}
              <a
                rel="noreferer"
                target="_blank"
                href="https://discord.gg/w2H9shYDHm"
              >
                <FaDiscord />
              </a>
              <a
                rel="noreferer"
                target="_blank"
                href="https://d.tube/#!/c/devlandnetwork00"
              >
                <SiDtube />
              </a>
              {/* <a
              rel="noreferer"
              target="_blank"
              href="https://www.youtube.com/channel/UCrBCuMc9DyZoxbv7vDdf5Fg"
            >
              <FaYoutube />
            </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="text-left md:text-right text-xl mt-8">
        <p>&copy; {new Date().getFullYear()} DevLand Network</p>
      </div>
    </footer>
  )
}
