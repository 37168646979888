import axios from "axios"
import { Keypair, Transaction, Networks } from "stellar-sdk"

import { prepareDataForRegisterRequest } from "./account.service"
import {
  getJwt,
  removeEncryptedSecret,
  removeJwt,
  removePublicKey,
} from "./localstorage.service"

const baseUrl = process.env.GATSBY_API_BASE_URL

const authEndpoint = `${baseUrl}/api/auth`
const registerEndpoint = `${baseUrl}/api/auth/register`

const clientPublicKey = process.env.GATSBY_SERVER_PUBLIC_KEY

export const register = async (firstName, lastName, username, publicKey) => {
  return await axios.post(
    registerEndpoint,
    {
      ...prepareDataForRegisterRequest({
        firstName,
        lastName,
        username,
        publicKey,
      }),
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
}

export const auth = async secret => {
  const clientPair = Keypair.fromSecret(secret)
  const serverPair = Keypair.fromPublicKey(clientPublicKey)
  const { data } = await axios.get(authEndpoint, {
    params: { account: clientPair.publicKey() },
  })
  console.log("Challenge transaction XDR:", data.transaction)
  const tx = new Transaction(data.transaction, Networks.TESTNET)
  const { signatures } = tx
  const hash = tx.hash()
  const valid = signatures.some(signature =>
    serverPair.verify(hash, signature.signature())
  )
  if (!valid) throw new Error("Server signature not found")
  tx.sign(clientPair)
  const signed = tx.toEnvelope().toXDR("base64")
  console.log("Signed transaction XDR:", signed)
  const {
    data: { token },
  } = await axios.post(
    authEndpoint,
    { transaction: signed },
    { withCredentials: true }
  )
  return token
}

export const logout = () => {
  removeJwt()
  removePublicKey()
  removeEncryptedSecret()
}

export const checkAuth = async () => {
  const token = getJwt()
  if (!token) return false
  try {
    const { data } = await axios.get(`${authEndpoint}/check-session`, {
      params: { token },
    })
    return data.valid
  } catch (e) {
    return false
  }
}
